import jQuery from 'jquery';

class Stickies
{
	static Init()
	{
		Stickies.Archive(); jQuery(window).on('resize', Stickies.Archive);
	}

	static Archive($sidebar)
	{
		jQuery('body.archive .sidebar.sticky, body.single-post .sidebar.sticky').each((i, el) =>
		{
			const $sidebar = jQuery(el);
			if (jQuery('body').is('[data-elementor-device-mode="desktop"]'))
			{
				const $content = $sidebar.prev();
				const $pagination = $content.find('.pagination').parent();
				const $wrap = $sidebar.find('.elementor-widget-wrap:first');
				const $children = $wrap.children();

				const bordT = $wrap.parent().outerHeight() - $wrap.parent().innerHeight();
				const paddT = $wrap.parent().innerHeight() - $wrap.parent().height();
				const margT = $wrap.parent().outerHeight(true) - $wrap.parent().outerHeight();

				const height = Math.ceil($children.map((ii, child) => jQuery(child).outerHeight(true)).toArray().reduce((a, b) => a + b, 0))
					+ bordT + paddT + margT + $pagination.outerHeight(true);
				$sidebar.css('height', height);
			}
			else
			{
				$sidebar.css('height', '');
			}
		});
	}
}

export default {
	init() {
		// JavaScript to be fired on all pages
		Stickies.Init();
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	},
};
